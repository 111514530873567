var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"medidetale"},[_c('div',{staticClass:"detale"},[_c('div',{staticClass:"fanhuibtn"},[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-arrow-left"},on:{"click":_vm.returnClick}},[_vm._v("返回")])],1),_c('div',{staticClass:"contentS",domProps:{"innerHTML":_vm._s(_vm.content)}}),_c('div',{staticClass:"fileAll"},_vm._l((_vm.fileList),function(item,index){return _c('div',{key:index,staticClass:"itemA",on:{"click":function($event){return _vm.ItemClick(item)}}},[_c('div',{staticClass:"fileListImg"},[(
                    item.storageName.slice(-4) == '.pdf' ||
                    item.storageName.slice(-4) == '.PDF'
                  )?_c('img',{attrs:{"src":require("../../assets/img/pdf.png"),"alt":""}}):_vm._e(),(
                    item.storageName.slice(-4) == '.doc' ||
                    item.storageName.slice(-5) == '.docx'
                  )?_c('img',{attrs:{"src":require("../../assets/img/word.png"),"alt":""}}):_vm._e(),(
                    item.storageName.slice(-4) == '.xls' ||
                    item.storageName.slice(-5) == '.xlsx'
                  )?_c('img',{attrs:{"src":require("../../assets/img/excel.png"),"alt":""}}):_vm._e(),(
                    item.storageName.slice(-4) == '.mp4' ||
                    item.storageName.slice(-5) == '.mp4'
                  )?_c('img',{attrs:{"src":require("../../assets/img/video.png"),"alt":""}}):_vm._e(),_c('a',{attrs:{"href":_vm.baseUrl + 'third/third/ossGetFile/' + item.storageName,"target":"_blank"}},[_vm._v(_vm._s(item.originalName))])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }